#menu-bar{
  width: 98%;
  margin: 3vh auto;
  display: flex;
  justify-content: space-between;
  align-items:center;
  z-index: 2;
  position: fixed;
  left: 0;
  right: 0;
  #main-Logo{
    width: 5vw;
    cursor: pointer;
  }

  #contact-Box{
    display: flex;
    align-items: center;
    img{
      margin-left: 1.5vw;
      width: 4vw;
      cursor: pointer;
    }
    div{
      margin-left: 1.5vw;
      cursor: pointer;
    }
    #mail-logo-container{
      @include flexCenter();
      font-family: 'BaiJamjuree';
      font-size: 1.5vw;
      color:$secondaryColor;
      img{
        margin: 0;
      }
    }
    #menuCVAndProjects-btn{
      width: 5vw;
      margin-right: 0;
      .btn-Cv-Project{
        border:1.5px solid $secondaryColor;
        height: 1.7vw;
        @include flexCenter();
        margin:0.4vw auto;
        background-color: black;
        color: #c8c0c0;
        border-radius: 5px;
        font-family: 'elephant';
        font-size: 0.9vw;
      }
      .projectbtn-menu{
        height: 2.3vw;
        text-align: center;
      }
    }
  }
}
@import './menuiPad';
@import './menuPhone';