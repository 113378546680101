.viewport { 
  height: 35vh;
  @include flexCenter();
  align-items: flex-end;
  .scene3D-container {
    position: fixed;
    width: 100%;
    height: 100%;
    perspective: calc(var(--scenePerspective) * var(--cameraSpeed) * 1px);
    perspective-origin: calc(var(--scenePerspectiveOriginX) * 1%) calc( var(--scenePerspectiveOriginY) * 1% );
    will-change: perspective-origin;
    transform: translate3d( 0, 0, 0 ); //Allows Hardware-Accelerated CSS, so transitions are smoother 
    @include flexCenter();
    .scene3D { 
      position: absolute; top: 0;
      height: 100vh;
      width: 100%;
      transform-style: preserve-3d;
      transform: translateZ(calc(var(--cameraZ) * 1px));
      will-change: transform; 
      @include flexCenter();
      div { 
        // border: 1px solid rgb(111, 0, 255);
        position: absolute; 
        top: 30%; 
        @media only screen and (min-width: 35vw) { 
          width: 100vw; 
        } 
        @for $i from 0 through 25 { 
         &:nth-child(#{$i}) { 
          transform: translate3D( 0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * #{$i} * -1px) ); 
         } 
        } 
        @include flexCenter();
        &:nth-child(2){
          width: 85vw;
        }
        img{
          width: 100%;
        }
      }
    }
  }
}