.my-fade-in{
  animation: myFadeIn 0.5s;
}

@keyframes myFadeIn {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

#first-slide{
  flex-direction: column;
  #name{
    margin-top: 9vh;
    width: 50vw;
  }
  #myCareertitle{
   margin-top: 7vh;
    width: 35vw;
  }
}
#slide2-text-container{
  z-index: 1;
  position: absolute;
  top: 7vh;//for align top
  flex-direction: column;
  color:$secondaryColor;
  #slide2-title{
    font-family: 'Elephant';
    font-size: 10vw;
  }
  #slide2-text{
    font-family:'BaiJamjuree' ;
    font-size: 3.5vw;
    width: 70vw;
    margin-top: 8vh;
    text-align: center;  
  }
}
#slide3-text-container{
  z-index: 1;
  position: absolute;
  top: 25vh; 
  flex-direction: column;
  color:$secondaryColor;
  #slide3-title{
    font-family: 'Elephant';
    font-size: 8vw;
  }
  #slide3-text{
    font-family:'BaiJamjuree' ;
    font-size: 4vw;
    width: 70vw;
    margin-top: 8vh;  
    text-align: center;
    img{
      width: 4vw;
      &:nth-child(4){
        width: 3vw;
      }
      &:nth-child(5){
        width: 6vw;
      }
      &:nth-child(6){
        width: 7vw;
      }
      &:nth-child(7){
        width: 11vw;
      }
    }
  }
}