@include iPadPort{
  #Projects_PhoneVersion{
    display: flex;
    flex-direction: column;
    justify-content: center;
  
    .Project_P_Container{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100vh;
      text-align: center;
  
      .Project_P_Title{
        color: $secondaryColor;
        font-family: 'elephant' ;
        font-size: 8vw;
        margin-bottom: 9vw;
        margin-top: 15vw;
        text-decoration: underline;
      }
  
      .Project_P_IMG{
        width: 92vw;
      }
  
      .Project_P_Info_Container{
        margin-top: 5vw;
        border: 3px solid $secondaryColor;
        width: 90vw;
        color:$secondaryColor;
  
        .Project_P_text{
          font-size: 3vw;
          font-family: 'BaiJamjuree';
          margin:1.5vw;
        }
  
        .Project_P_tools_Container{
         
          margin-top: 2vw;
          img{
            margin:2vw;
            width: 7vw;
          
          }
        }
      }
    }
    .arrowForProject{
      top:93vh;
      span{
        width: 2vw;
        height: 2vw;
      }
    }
  }
}