@include iPadPort{
  .viewport{
    height: 90vh;
  }
  .viewport .scene3D-container .scene3D div{
    &:nth-child(1){
      width: 240vw;
    }
    &:nth-child(2){
      width: 155vw;
    }
    &:nth-child(3){
      width: 182vw;
    }
  }
  #first-slide{
    #name{
      width: 170vw;
    }
    #myCareertitle{
      width: 125vw;
    }
  }
  #second-slide{
    #slide2-text-container{
      #slide2-title{
        font-size: 17vw;
      }
      #slide2-text{
        font-size: 7.5vw;
        width: 135vw;
        margin-top: 2vh;
      }
    }
  }
  #Third-slide{
    #slide3-text-container{
      top: 10vh;
      #slide3-title{
        font-size: 17vw;
      }
      #slide3-text{
        font-size: 9vw;
        width: 154vw;
        margin-top: 1vh;
        img{
          width: 10vw;
          &:nth-child(6){
            width: 15vw;
          }
        }
      }
    }
  }
  .arrow{
    top: 89vh;
    span{
      width: 5vw;
      height: 5vw;
    }
  }
}

@include iPadLand{
  .viewport{
    height: 90vh;
  }
  .viewport .scene3D-container .scene3D div{
    &:nth-child(1){
      width: 150vw;
    }
    &:nth-child(2){
      width: 105vw;
    }
    &:nth-child(3){
      width: 150vw;
    }
  }
  #first-slide{
    #name{
      width: 95vw;
    }
    #myCareertitle{
      width: 65vw;
    }
  }
  #second-slide{
    #slide2-text-container{
      #slide2-title{
        font-size: 12vw;
      }
      #slide2-text{
        font-size: 5vw;
        width: 90vw;
        margin-top: 2vh;
      }
    }
  }
  #Third-slide{
    #slide3-text-container{
      top: 10vh;
      #slide3-title{
        font-size: 11vw;
      }
      #slide3-text{
        font-size: 7vw;
        width: 130vw;
        margin-top: 8vh;
        img{
          width: 8vw;
          &:nth-child(6){
            width: 11vw;
          }
        }
      }
    }
  }
  .arrow{
    top: 91vh;
    span{
      width: 2vw;
      height: 2vw;
    }
  }
}