@include Phone {
  #menu-bar{
    margin-top: 1vw;
    justify-content: start;
    width: 63%;
    #main-Logo{
      width: 15vw;
    }
    #contact-Box{
      img{
        width: 14vw;
      }
      #mail-logo-container{
        font-size: 5vw;
        span{
          position: fixed;
          margin-top: 23vw;
          margin-left: 13vw;
        }
      }
      #menuCVAndProjects-btn {
        .btn-Cv-Project{
          height: 5.8vw;
          width: 15vw;
          font-size: 3.2vw;
        }
      }
    }
  }
}
