#Project-Menu{
  height: 100vh;
  width: 7vw;
  @include flexCenter();
  flex-direction: column;
  z-index: 1;
 #project-dot-container{
   background-color: #c8c0c0;
   .dot {
     margin:1.2vw;
     height: 2.5vw;
     width: 2.5vw;
     background-color: rgb(0, 0, 0);
     border-radius: 50%;
     @include flexCenter();
     cursor: pointer;
     .selectedDot{
       z-index: 1;
       height:calc(100% - 1vw);
       width:calc(100% - 1vw);
       background-color: #c8c0c0;
       border-radius: 50%;
     }
   }
 }
}
