#project1{
  #project1-Main-Img{
    img{
     &:nth-child(1){
       margin-left: 34vw;
    }
    &:nth-child(2){
      position: absolute;
      margin-left: -33vw;
      margin-top: 2vw;
     }
    }
  }
  #project-1-description{
    margin-left: 7vw;
    margin-top: 5vw;
  }
}