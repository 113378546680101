:root {
  --scenePerspective: 1;
  --scenePerspectiveOriginX: 50;
  --scenePerspectiveOriginY: 30;
  --itemZ: 2;
  --cameraSpeed: 180;
  --cameraZ: 0;
  --viewportHeight: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "elephant";
  src: url("../fonts/Elephant.ttf") format("truetype");
}
@font-face {
  font-family: "BaiJamjuree";
  src: url("../fonts/BaiJamjuree.ttf") format("truetype");
}
#menu-bar {
  width: 98%;
  margin: 3vh auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  position: fixed;
  left: 0;
  right: 0;
}
#menu-bar #main-Logo {
  width: 5vw;
  cursor: pointer;
}
#menu-bar #contact-Box {
  display: flex;
  align-items: center;
}
#menu-bar #contact-Box img {
  margin-left: 1.5vw;
  width: 4vw;
  cursor: pointer;
}
#menu-bar #contact-Box div {
  margin-left: 1.5vw;
  cursor: pointer;
}
#menu-bar #contact-Box #mail-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "BaiJamjuree";
  font-size: 1.5vw;
  color: #c8c0c0;
}
#menu-bar #contact-Box #mail-logo-container img {
  margin: 0;
}
#menu-bar #contact-Box #menuCVAndProjects-btn {
  width: 5vw;
  margin-right: 0;
}
#menu-bar #contact-Box #menuCVAndProjects-btn .btn-Cv-Project {
  border: 1.5px solid #c8c0c0;
  height: 1.7vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.4vw auto;
  background-color: black;
  color: #c8c0c0;
  border-radius: 5px;
  font-family: "elephant";
  font-size: 0.9vw;
}
#menu-bar #contact-Box #menuCVAndProjects-btn .projectbtn-menu {
  height: 2.3vw;
  text-align: center;
}

@media (orientation: landscape) and (max-width: 1024px) {
  #menu-bar {
    margin-top: 1vw;
  }
  #menu-bar #main-Logo {
    width: 8vw;
  }
  #menu-bar #contact-Box {
    width: 23.7vw;
  }
  #menu-bar #contact-Box img {
    width: 6vw;
  }
  #menu-bar #contact-Box #menuCVAndProjects-btn .btn-Cv-Project {
    height: 2.4vw;
    width: 7vw;
    font-size: 1.4vw;
  }
  #menu-bar #contact-Box #mail-logo-container {
    font-size: 2.5vw;
  }
}
@media (orientation: portrait) and (max-width: 768px) {
  #menu-bar {
    margin-top: 1vw;
    justify-content: center;
  }
  #menu-bar #main-Logo {
    width: 10vw;
  }
  #menu-bar #contact-Box img {
    width: 9vw;
  }
  #menu-bar #contact-Box #mail-logo-container {
    font-size: 3vw;
  }
  #menu-bar #contact-Box #menuCVAndProjects-btn .btn-Cv-Project {
    height: 3.8vw;
    width: 10vw;
    font-size: 2.1vw;
  }
}
@media (orientation: portrait) and (max-width: 414px) {
  #menu-bar {
    margin-top: 1vw;
    justify-content: start;
    width: 63%;
  }
  #menu-bar #main-Logo {
    width: 15vw;
  }
  #menu-bar #contact-Box img {
    width: 14vw;
  }
  #menu-bar #contact-Box #mail-logo-container {
    font-size: 5vw;
  }
  #menu-bar #contact-Box #mail-logo-container span {
    position: fixed;
    margin-top: 23vw;
    margin-left: 13vw;
  }
  #menu-bar #contact-Box #menuCVAndProjects-btn .btn-Cv-Project {
    height: 5.8vw;
    width: 15vw;
    font-size: 3.2vw;
  }
}
.arrow {
  position: fixed;
  top: 90vh;
  left: 49.5vw;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.arrow span {
  display: block;
  width: 1vw;
  height: 1vw;
  border-bottom: 1vw solid #c8c0c0;
  border-right: 1vw solid #c8c0c0;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}
.viewport {
  height: 35vh;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: flex-end;
}
.viewport .scene3D-container {
  position: fixed;
  width: 100%;
  height: 100%;
  perspective: calc(var(--scenePerspective) * var(--cameraSpeed) * 1px);
  perspective-origin: calc(var(--scenePerspectiveOriginX) * 1%) calc(var(--scenePerspectiveOriginY) * 1%);
  will-change: perspective-origin;
  transform: translate3d(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
}
.viewport .scene3D-container .scene3D {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100%;
  transform-style: preserve-3d;
  transform: translateZ(calc(var(--cameraZ) * 1px));
  will-change: transform;
  display: flex;
  justify-content: center;
  align-items: center;
}
.viewport .scene3D-container .scene3D div {
  position: absolute;
  top: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 35vw) {
  .viewport .scene3D-container .scene3D div {
    width: 100vw;
  }
}
.viewport .scene3D-container .scene3D div:nth-child(0) {
  transform: translate3D(0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * 0 * -1px));
}
.viewport .scene3D-container .scene3D div:nth-child(1) {
  transform: translate3D(0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * 1 * -1px));
}
.viewport .scene3D-container .scene3D div:nth-child(2) {
  transform: translate3D(0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * 2 * -1px));
}
.viewport .scene3D-container .scene3D div:nth-child(3) {
  transform: translate3D(0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * 3 * -1px));
}
.viewport .scene3D-container .scene3D div:nth-child(4) {
  transform: translate3D(0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * 4 * -1px));
}
.viewport .scene3D-container .scene3D div:nth-child(5) {
  transform: translate3D(0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * 5 * -1px));
}
.viewport .scene3D-container .scene3D div:nth-child(6) {
  transform: translate3D(0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * 6 * -1px));
}
.viewport .scene3D-container .scene3D div:nth-child(7) {
  transform: translate3D(0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * 7 * -1px));
}
.viewport .scene3D-container .scene3D div:nth-child(8) {
  transform: translate3D(0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * 8 * -1px));
}
.viewport .scene3D-container .scene3D div:nth-child(9) {
  transform: translate3D(0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * 9 * -1px));
}
.viewport .scene3D-container .scene3D div:nth-child(10) {
  transform: translate3D(0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * 10 * -1px));
}
.viewport .scene3D-container .scene3D div:nth-child(11) {
  transform: translate3D(0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * 11 * -1px));
}
.viewport .scene3D-container .scene3D div:nth-child(12) {
  transform: translate3D(0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * 12 * -1px));
}
.viewport .scene3D-container .scene3D div:nth-child(13) {
  transform: translate3D(0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * 13 * -1px));
}
.viewport .scene3D-container .scene3D div:nth-child(14) {
  transform: translate3D(0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * 14 * -1px));
}
.viewport .scene3D-container .scene3D div:nth-child(15) {
  transform: translate3D(0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * 15 * -1px));
}
.viewport .scene3D-container .scene3D div:nth-child(16) {
  transform: translate3D(0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * 16 * -1px));
}
.viewport .scene3D-container .scene3D div:nth-child(17) {
  transform: translate3D(0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * 17 * -1px));
}
.viewport .scene3D-container .scene3D div:nth-child(18) {
  transform: translate3D(0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * 18 * -1px));
}
.viewport .scene3D-container .scene3D div:nth-child(19) {
  transform: translate3D(0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * 19 * -1px));
}
.viewport .scene3D-container .scene3D div:nth-child(20) {
  transform: translate3D(0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * 20 * -1px));
}
.viewport .scene3D-container .scene3D div:nth-child(21) {
  transform: translate3D(0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * 21 * -1px));
}
.viewport .scene3D-container .scene3D div:nth-child(22) {
  transform: translate3D(0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * 22 * -1px));
}
.viewport .scene3D-container .scene3D div:nth-child(23) {
  transform: translate3D(0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * 23 * -1px));
}
.viewport .scene3D-container .scene3D div:nth-child(24) {
  transform: translate3D(0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * 24 * -1px));
}
.viewport .scene3D-container .scene3D div:nth-child(25) {
  transform: translate3D(0%, 0%, calc(var(--itemZ) * var(--cameraSpeed) * 25 * -1px));
}
.viewport .scene3D-container .scene3D div:nth-child(2) {
  width: 85vw;
}
.viewport .scene3D-container .scene3D div img {
  width: 100%;
}

.my-fade-in {
  animation: myFadeIn 0.5s;
}

@keyframes myFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
#first-slide {
  flex-direction: column;
}
#first-slide #name {
  margin-top: 9vh;
  width: 50vw;
}
#first-slide #myCareertitle {
  margin-top: 7vh;
  width: 35vw;
}

#slide2-text-container {
  z-index: 1;
  position: absolute;
  top: 7vh;
  flex-direction: column;
  color: #c8c0c0;
}
#slide2-text-container #slide2-title {
  font-family: "Elephant";
  font-size: 10vw;
}
#slide2-text-container #slide2-text {
  font-family: "BaiJamjuree";
  font-size: 3.5vw;
  width: 70vw;
  margin-top: 8vh;
  text-align: center;
}

#slide3-text-container {
  z-index: 1;
  position: absolute;
  top: 25vh;
  flex-direction: column;
  color: #c8c0c0;
}
#slide3-text-container #slide3-title {
  font-family: "Elephant";
  font-size: 8vw;
}
#slide3-text-container #slide3-text {
  font-family: "BaiJamjuree";
  font-size: 4vw;
  width: 70vw;
  margin-top: 8vh;
  text-align: center;
}
#slide3-text-container #slide3-text img {
  width: 4vw;
}
#slide3-text-container #slide3-text img:nth-child(4) {
  width: 3vw;
}
#slide3-text-container #slide3-text img:nth-child(5) {
  width: 6vw;
}
#slide3-text-container #slide3-text img:nth-child(6) {
  width: 7vw;
}
#slide3-text-container #slide3-text img:nth-child(7) {
  width: 11vw;
}

@media (orientation: portrait) and (max-width: 768px) {
  .viewport {
    height: 90vh;
  }
  .viewport .scene3D-container .scene3D div:nth-child(1) {
    width: 240vw;
  }
  .viewport .scene3D-container .scene3D div:nth-child(2) {
    width: 155vw;
  }
  .viewport .scene3D-container .scene3D div:nth-child(3) {
    width: 182vw;
  }
  #first-slide #name {
    width: 170vw;
  }
  #first-slide #myCareertitle {
    width: 125vw;
  }
  #second-slide #slide2-text-container #slide2-title {
    font-size: 17vw;
  }
  #second-slide #slide2-text-container #slide2-text {
    font-size: 7.5vw;
    width: 135vw;
    margin-top: 2vh;
  }
  #Third-slide #slide3-text-container {
    top: 10vh;
  }
  #Third-slide #slide3-text-container #slide3-title {
    font-size: 17vw;
  }
  #Third-slide #slide3-text-container #slide3-text {
    font-size: 9vw;
    width: 154vw;
    margin-top: 1vh;
  }
  #Third-slide #slide3-text-container #slide3-text img {
    width: 10vw;
  }
  #Third-slide #slide3-text-container #slide3-text img:nth-child(6) {
    width: 15vw;
  }
  .arrow {
    top: 89vh;
  }
  .arrow span {
    width: 5vw;
    height: 5vw;
  }
}
@media (orientation: landscape) and (max-width: 1024px) {
  .viewport {
    height: 90vh;
  }
  .viewport .scene3D-container .scene3D div:nth-child(1) {
    width: 150vw;
  }
  .viewport .scene3D-container .scene3D div:nth-child(2) {
    width: 105vw;
  }
  .viewport .scene3D-container .scene3D div:nth-child(3) {
    width: 150vw;
  }
  #first-slide #name {
    width: 95vw;
  }
  #first-slide #myCareertitle {
    width: 65vw;
  }
  #second-slide #slide2-text-container #slide2-title {
    font-size: 12vw;
  }
  #second-slide #slide2-text-container #slide2-text {
    font-size: 5vw;
    width: 90vw;
    margin-top: 2vh;
  }
  #Third-slide #slide3-text-container {
    top: 10vh;
  }
  #Third-slide #slide3-text-container #slide3-title {
    font-size: 11vw;
  }
  #Third-slide #slide3-text-container #slide3-text {
    font-size: 7vw;
    width: 130vw;
    margin-top: 8vh;
  }
  #Third-slide #slide3-text-container #slide3-text img {
    width: 8vw;
  }
  #Third-slide #slide3-text-container #slide3-text img:nth-child(6) {
    width: 11vw;
  }
  .arrow {
    top: 91vh;
  }
  .arrow span {
    width: 2vw;
    height: 2vw;
  }
}
@media (orientation: portrait) and (max-width: 414px) {
  .viewport {
    height: 90vh;
  }
  .viewport .scene3D-container .scene3D div:nth-child(1) {
    width: 240vw;
  }
  .viewport .scene3D-container .scene3D div:nth-child(2) {
    width: 159vw;
  }
  .viewport .scene3D-container .scene3D div:nth-child(3) {
    width: 185vw;
  }
  #first-slide #name {
    width: 170vw;
  }
  #first-slide #myCareertitle {
    width: 125vw;
  }
  #second-slide #slide2-text-container #slide2-title {
    font-size: 17vw;
  }
  #second-slide #slide2-text-container #slide2-text {
    font-size: 7vw;
    width: 125vw;
    margin-top: 1vh;
  }
  #Third-slide #slide3-text-container {
    top: 10vh;
  }
  #Third-slide #slide3-text-container #slide3-title {
    font-size: 17vw;
  }
  #Third-slide #slide3-text-container #slide3-text {
    font-size: 9vw;
    width: 169vw;
    margin-top: 1vh;
  }
  #Third-slide #slide3-text-container #slide3-text img {
    width: 13vw;
  }
  .arrow {
    top: 80vh;
  }
  .arrow span {
    width: 7vw;
    height: 7vw;
  }
}
#Front-Page {
  height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
#Front-Page .viewport {
  margin-top: 30em;
}

@media (orientation: portrait) and (max-width: 768px) {
  .viewport {
    margin-top: 10em;
  }
}
@media (orientation: landscape) and (max-width: 1024px) {
  .viewport {
    margin-top: 5em;
  }
}
@media (orientation: portrait) and (max-width: 414px) {
  #Front-Page {
    height: 100vh;
  }
  #Front-Page .viewport {
    margin-top: 5em;
  }
}
#Project-Page {
  height: 100vh;
  display: flex;
}
#Project-Page #Project-Menu {
  height: 100vh;
  width: 7vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}
#Project-Page #Project-Menu #project-dot-container {
  background-color: #c8c0c0;
}
#Project-Page #Project-Menu #project-dot-container .dot {
  margin: 1.2vw;
  height: 2.5vw;
  width: 2.5vw;
  background-color: rgb(0, 0, 0);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
#Project-Page #Project-Menu #project-dot-container .dot .selectedDot {
  z-index: 1;
  height: calc(100% - 1vw);
  width: calc(100% - 1vw);
  background-color: #c8c0c0;
  border-radius: 50%;
}
#Project-Page #Projects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 7vw;
  padding-top: 5vw;
}
#Project-Page #Projects .project-container {
  width: 65vw;
  display: flex;
  margin: 1.6vw;
}
#Project-Page #Projects .project-container img {
  width: 35vw;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
#Project-Page #Projects .project-container .project-title {
  color: #c8c0c0;
  font-family: "Elephant";
  text-decoration: underline;
  font-size: 2vw;
  position: absolute;
  z-index: 1;
  width: 23vw;
}
#Project-Page #Projects .project-container .project-information {
  width: 30vw;
  height: 9.8vw;
  background-color: black;
  color: #c8c0c0;
  font-family: "BaiJamjuree";
  border: 2px solid #c8c0c0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  z-index: 1;
}
#Project-Page #Projects .project-container .project-information span {
  width: 25vw;
  margin-top: 1vw;
  font-size: 1.1vw;
  height: 5.5vw;
}
#Project-Page #Projects .project-container .project-information .tools-used {
  align-self: baseline;
  width: 20vw;
  margin-left: 2.5vw;
}
#Project-Page #Projects .project-container .project-information .tools-used img {
  box-shadow: none;
  margin-top: 0.5vw;
  max-width: 2vw;
  max-height: 2vw;
}
#Project-Page #Projects .project-container .project-information .tools-used img:nth-child(n+2) {
  margin-left: 1vw;
}
#Project-Page #Projects #project1 #project1-Main-Img img:nth-child(1) {
  margin-left: 34vw;
}
#Project-Page #Projects #project1 #project1-Main-Img img:nth-child(2) {
  position: absolute;
  margin-left: -33vw;
  margin-top: 2vw;
}
#Project-Page #Projects #project1 #project-1-description {
  margin-left: 7vw;
  margin-top: 5vw;
}
#Project-Page #Projects #project2 {
  width: 69vw;
}
#Project-Page #Projects #project2 #project2-Main-Img img:nth-child(1) {
  margin-left: 1vw;
}
#Project-Page #Projects #project2 #project2-Main-Img img:nth-child(2) {
  margin-top: 2vw;
  position: absolute;
  z-index: 1;
  margin-left: -33vw;
}
#Project-Page #Projects #project2 #project2-info-container {
  text-align: right;
}
#Project-Page #Projects #project2 #project2-info-container #project-2-title {
  margin-left: 10vw;
}
#Project-Page #Projects #project2 #project2-info-container #project-2-description {
  margin-top: 5vw;
  margin-left: -3vw;
  text-align: left;
}

@media (orientation: landscape) and (max-width: 1024px) {
  #Project-Page #Projects {
    margin: 0 5vw;
  }
  #Project-Page #Projects .project-container img {
    width: 47vw;
  }
  #Project-Page #Projects .project-container .project-title {
    font-size: 3vw;
  }
  #Project-Page #Projects .project-container .project-information {
    width: 35vw;
    height: 11.8vw;
  }
  #Project-Page #Projects .project-container .project-information span {
    width: 33vw;
    font-size: 1.4vw;
  }
  #Project-Page #Projects .project-container .project-information .tools-used {
    margin-top: 1.5vw;
  }
  #Project-Page #Projects #project2 #project2-info-container #project-2-title {
    margin-left: 5vw;
  }
  #Project-Page #Projects #project2 #project2-info-container #project-2-description {
    margin-top: 8vw;
  }
  #Project-Page #Projects #project1 #project-1-description {
    margin-left: 2vw;
    margin-top: 8vw;
  }
}
@media (orientation: portrait) and (max-width: 768px) {
  #Projects_PhoneVersion {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  #Projects_PhoneVersion .Project_P_Container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    text-align: center;
  }
  #Projects_PhoneVersion .Project_P_Container .Project_P_Title {
    color: #c8c0c0;
    font-family: "elephant";
    font-size: 8vw;
    margin-bottom: 9vw;
    margin-top: 15vw;
    text-decoration: underline;
  }
  #Projects_PhoneVersion .Project_P_Container .Project_P_IMG {
    width: 92vw;
  }
  #Projects_PhoneVersion .Project_P_Container .Project_P_Info_Container {
    margin-top: 5vw;
    border: 3px solid #c8c0c0;
    width: 90vw;
    color: #c8c0c0;
  }
  #Projects_PhoneVersion .Project_P_Container .Project_P_Info_Container .Project_P_text {
    font-size: 3vw;
    font-family: "BaiJamjuree";
    margin: 1.5vw;
  }
  #Projects_PhoneVersion .Project_P_Container .Project_P_Info_Container .Project_P_tools_Container {
    margin-top: 2vw;
  }
  #Projects_PhoneVersion .Project_P_Container .Project_P_Info_Container .Project_P_tools_Container img {
    margin: 2vw;
    width: 7vw;
  }
  #Projects_PhoneVersion .arrowForProject {
    top: 93vh;
  }
  #Projects_PhoneVersion .arrowForProject span {
    width: 2vw;
    height: 2vw;
  }
}
@media (orientation: portrait) and (max-width: 414px) {
  #Projects_PhoneVersion .Project_P_Container .Project_P_Title {
    color: #c8c0c0;
    font-family: "elephant";
    font-size: 10vw;
    margin-top: 0vw;
    margin-bottom: 9vw;
    text-decoration: underline;
  }
  #Projects_PhoneVersion .Project_P_Container .Project_P_IMG {
    width: 92vw;
  }
  #Projects_PhoneVersion .Project_P_Container .Project_P_Info_Container .Project_P_text {
    font-size: 4vw;
  }
  #Projects_PhoneVersion .arrowForProject {
    top: 94vh;
  }
  #Projects_PhoneVersion .arrowForProject span {
    width: 3vw;
    height: 3vw;
  }
}
.GithubProjects_ICON:hover {
  cursor: pointer;
}

.liveURL_ICON:hover {
  cursor: pointer;
}/*# sourceMappingURL=index.css.map */