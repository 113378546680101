.arrow{
    position: fixed;
    top: 90vh;
    left: 49.5vw;
    transform: translate(-50%,-50%);
    z-index: 1;
}
.arrow span{
    display: block;
    width: 1vw;
    height: 1vw;
    border-bottom: 1vw solid $secondaryColor;
    border-right: 1vw solid $secondaryColor;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;
}
.arrow span:nth-child(2){
    animation-delay: -0.2s;
}
.arrow span:nth-child(3){
    animation-delay: -0.4s;
}
@keyframes animate {
    0%{
        opacity: 0;
        transform: rotate(45deg) translate(-20px,-20px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(20px,20px);
    }
}