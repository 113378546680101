@include iPadLand {
  #menu-bar{
    margin-top: 1vw;
    #main-Logo{
      width: 8vw;
    }
    #contact-Box{
      width: 23.7vw;
      img{
        width: 6vw;
      }
      #menuCVAndProjects-btn {
        .btn-Cv-Project{
          height: 2.4vw;
          width: 7vw;
          font-size: 1.4vw;
        }
      }
      #mail-logo-container{
        font-size: 2.5vw;
      }
    }
  }
}

@include iPadPort {
  #menu-bar{
    margin-top: 1vw;
    justify-content: center;
    #main-Logo{
      width: 10vw;
    }
    #contact-Box{
      img{
        width: 9vw;
      }
      #mail-logo-container{
        font-size: 3vw;
      }
      #menuCVAndProjects-btn {
        .btn-Cv-Project{
          height: 3.8vw;
          width: 10vw;
          font-size: 2.1vw;
        }
      }
     
    }
  }
}
