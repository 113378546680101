@include Phone{
  .viewport{
    height: 90vh;
  }
  .viewport .scene3D-container .scene3D div{
    &:nth-child(1){
      width: 240vw;
    }
    &:nth-child(2){
      width: 159vw;
    }
    &:nth-child(3){
      width: 185vw;
    }
  }
  #first-slide{
    #name{
      width: 170vw;
    }
    #myCareertitle{
      width: 125vw;
    }
  }
  #second-slide{
    #slide2-text-container{
      #slide2-title{
        font-size: 17vw;
      }
      #slide2-text{
        font-size: 7vw;
        width: 125vw;
        margin-top: 1vh;
      }
    }
  }
  #Third-slide{
    #slide3-text-container{
      top: 10vh;
      #slide3-title{
        font-size: 17vw;
      }
      #slide3-text{
        font-size: 9vw;
        width: 169vw;
        margin-top: 1vh;
        img{
          width: 13vw;
        }
      }
    }
  }
  .arrow{
    top: 80vh;
    span{
      width: 7vw;
      height: 7vw;
    }
  }
}