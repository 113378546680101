@import './ProjectiPadPort';


@include Phone{
  #Projects_PhoneVersion{
    .Project_P_Container{
      .Project_P_Title{
        color: $secondaryColor;
        font-family: 'elephant' ;
        font-size: 10vw;
        margin-top: 0vw;
        margin-bottom: 9vw;
        text-decoration: underline;
      }
      .Project_P_IMG{
        width: 92vw;
      }
      .Project_P_Info_Container{
        .Project_P_text{
          font-size: 4vw;
        }
      }
    }
    .arrowForProject{
      top:94vh;
      span{
        width: 3vw;
        height: 3vw;
      }
    }
  }
}

