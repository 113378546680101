@include iPadLand{
  #Project-Page #Projects{
    margin: 0 5vw;
    .project-container{
      img{
        width: 47vw;
      }
      .project-title{
        font-size: 3vw;
      }
      .project-information{
        width: 35vw;
        height: 11.8vw;
        span{
          width: 33vw;
          font-size: 1.4vw;
        }
        .tools-used{
          margin-top: 1.5vw;
        }
      }
    }
  }
  #Project-Page #Projects #project2 #project2-info-container{
    #project-2-title{
      margin-left: 5vw;
    }
    #project-2-description{
      margin-top: 8vw;
    }
  }
  
  
  #Project-Page #Projects #project1 #project-1-description{
    margin-left: 2vw;
    margin-top: 8vw;
  }  
}

