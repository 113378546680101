#Project-Page{
  height: 100vh;
  display: flex;
  
  @import './ProjectMenu';

  #Projects{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 7vw;
    padding-top: 5vw;
    .project-container{
      width: 65vw;
      display: flex;
      margin: 1.6vw;
      img{
        width: 35vw;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      };
      .project-title{
        color: $secondaryColor;
        font-family: 'Elephant';
        text-decoration: underline;
        font-size: 2vw;
        position: absolute;
        z-index: 1;
        width: 23vw;
      };
      .project-information{
        width: 30vw;
        height: 9.8vw;
        background-color: black;
        color:$secondaryColor;
        font-family: 'BaiJamjuree';
        border: 2px solid $secondaryColor;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        display: flex;;
        align-items: center;
        flex-direction: column;
        position: absolute;
        z-index: 1;
        span{
          width: 25vw;
          margin-top:1vw;
          font-size: 1.1vw;
          height: 5.5vw;
        }
        .tools-used{
          align-self: baseline;
          width: 20vw;
          margin-left: 2.5vw;
          img{
            box-shadow:none;
            margin-top: 0.5vw;
            max-width: 2vw;
            max-height: 2vw;
            &:nth-child(n+2){
              margin-left: 1vw;
            }
          }
        }
      };
    };
    @import './Project1Setting';
    @import './Project2Setting';
  }
}
@import './ProjectiPadLand';
@import './ProjectPhoneVersion/ProjectPhoneVersion';

.GithubProjects_ICON{
  &:hover{
    cursor: pointer;
  }
}

.liveURL_ICON{
  &:hover{
    cursor: pointer;
  }
}