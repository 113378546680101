:root {
  --scenePerspective: 1;
  --scenePerspectiveOriginX: 50;
  --scenePerspectiveOriginY: 30;
  --itemZ: 2;
  --cameraSpeed: 180; // Where 1 is the fastest
  --cameraZ: 0;
  --viewportHeight: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
//Variables
$secondaryColor:#c8c0c0;
$ipadLandscape:1024px;
$ipadPortrait:768px;
$iphonePortrait:414px;

//+++++++++ Fonts
@font-face {
  font-family: "elephant";
  src: url("../fonts/Elephant.ttf") format("truetype");
}
@font-face {
  font-family: "BaiJamjuree";
  src: url('../fonts/BaiJamjuree.ttf') format("truetype");
}

//+++++++++ Mixins
@mixin createBox($height, $width, $border){
  width: $width;
  height: $height;
  border:$border;
};
@mixin flexCenter(){
  display: flex;
  justify-content: center;
  align-items: center;
};
@mixin Ultra4K{
  @media (orientation:landscape) and (max-width:3840px){
    @content;
  }
}

@mixin Desktop{
  @media (orientation:landscape) and (max-width:1920px){
    @content;
  }
}
@mixin Laptop{
  @media (orientation:landscape) and (max-width:1536px){
    @content;
  }
}
@mixin iPadLand{
  @media (orientation:landscape) and (max-width: $ipadLandscape){
    @content;
  };
};
@mixin iPadPort{
  @media (orientation:portrait) and (max-width: $ipadPortrait){
    @content;
  };
};
@mixin Phone {
  @media (orientation:portrait) and (max-width: $iphonePortrait){
    @content;
  };
};

@import '../../menu/css/menu';
@import '../../MainPage/CSS/frontPage';
@import '../../projectsPage/CSS/Project.page';

