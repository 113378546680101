#project2{
  width: 69vw;
  #project2-Main-Img{
    img{
      &:nth-child(1){
        margin-left: 1vw;
      }
      &:nth-child(2){
        margin-top: 2vw;
        position: absolute; 
        z-index: 1;
        margin-left: -33vw;
      }
    }
  }
  #project2-info-container{
    text-align: right;
    #project-2-title{
      margin-left: 10vw;
    }
    #project-2-description{
      margin-top: 5vw;
      margin-left: -3vw;
      text-align: left;
    }
  }
}
