@import './arrowAnimation';
@import './3dAnimationConfig';
@import './Slides';
@import './iPad/frontpageiPad';
@import './Phone/frontpagePhone';
#Front-Page{
  height: 95vh;
  @include flexCenter();
  .viewport{
    margin-top: 30em;
  }
}

@include iPadPort{
  .viewport {
    margin-top: 10em;
  }
}
@include iPadLand{
  .viewport {
    margin-top: 5em;
  }
}

@include Phone{
  #Front-Page{
    height: 100vh;
    .viewport {
      margin-top: 5em;
    }
  }
}